import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import '../styles/SensorDraft.scss';

const sensorsSize = [
    {
        id: 0,
        img: "/draft/v50/rot_v50.webp",
        img2: "/draft/v50/stat_v50.webp",
        name: "V50",
        archive: "/angleSensors.pdf",
//        archive: "/draft/v50/v50.zip",
        draft1: "/draft/v50/1.webp",
        draft2: "/draft/v50/2.webp",
        draft3: "/draft/v50/3.webp",
        draft4: "/draft/v50/4.webp",
        keywords: {
            en: "centroplast, centroplast sensor, encoder, absolute encoder,  angle sensor, absolute angle sensor, rotary sensor, buy encoder, buy absolute encoder, absolute encoder buy ukraine, angle position sensor, inductive encoder, non-contact encoder, inductive rotation sensor, inductive angle sensors, rotary encoder, programmable encoder, angle measurement sensor, inductive angle sensor, non-contact angle sensor, non-contact rotation angle sensor, non-contact inductive angle sensor, reliable encoder, reliable rotation sensor, reliable angle sensor, ring encoder, inductive ring encoder, calibrated inductive encoder, inductive rotary encoder, highly reliable inductive rotary encoder",
            ua: "центропласт, центропласт датчик, енкодер, абсолютний енкодер, датчик кута, датчик абсолютного кута, поворотний датчик, енкодер купити, абсолютні енкодер купити, абсолютні енкодер купити україна, датчик кутового положення, індуктивний енкодер, безконтактний енкодер, індуктивний датчик повороту, індуктивний кутовий датчики, поворотні енкодер, програмований енкодер, датчик вимірювання кута, iндуктивний датчик кута повороту, безконтактний датчик кута, безконтактний датчик кута повороту, безконтактний індуктивний датчик кута повороту, надійний енкодер, надійний датчик повороту, надійний кутовий датчик, кільцевий енкодер, індуктивний кільцевий енкодер, відкалібрований індуктивний енкодер, індуктивний кутовий енкодер, високонадійний індуктивний кутовий енкодер",
            ru: "центропласт, центропласт датчик, энкодер, абсолютный энкодер, датчик угла, датчик абсолютного угла, поворотный датчик, энкодер купить, абсолютные энкодер купить, абсолютные энкодер купить украина, датчик углового положения, индуктивный энкодер, бесконтактный энкодер, индуктивный датчик поворота, индуктивный угловой датчики, поворотные энкодер, программируемый энкодер, датчик измерения угла, индуктивный датчик угла поворота, бесконтактный датчик угла, бесконтактный датчик угла поворота, бесконтактный индуктивный датчик угла поворота, надёжный энкодер, надежный датчик поворота, надёжный угловой датчик, кольцевой энкодер, индуктивный кольцевой энкодер, откалиброванный индуктивный энкодер, индуктивный угловой энкодер, высоконадёжный индуктивный угловой энкодер"
              },
        description: {
            en: "centroplast, centroplast sensor, encoder, absolute encoder,  angle sensor, absolute angle sensor, rotary sensor, buy encoder, buy absolute encoder, absolute encoder buy ukraine, angle position sensor, inductive encoder, non-contact encoder, inductive rotation sensor, inductive angle sensors, rotary encoder, programmable encoder, angle measurement sensor, inductive angle sensor, non-contact angle sensor, non-contact rotation angle sensor, non-contact inductive angle sensor, reliable encoder, reliable rotation sensor, reliable angle sensor, ring encoder, inductive ring encoder, calibrated inductive encoder, inductive rotary encoder, highly reliable inductive rotary encoder",
            ua: "центропласт, центропласт датчик, енкодер, абсолютний енкодер, датчик кута, датчик абсолютного кута, поворотний датчик, енкодер купити, абсолютні енкодер купити, абсолютні енкодер купити україна, датчик кутового положення, індуктивний енкодер, безконтактний енкодер, індуктивний датчик повороту, індуктивний кутовий датчики, поворотні енкодер, програмований енкодер, датчик вимірювання кута, iндуктивний датчик кута повороту, безконтактний датчик кута, безконтактний датчик кута повороту, безконтактний індуктивний датчик кута повороту, надійний енкодер, надійний датчик повороту, надійний кутовий датчик, кільцевий енкодер, індуктивний кільцевий енкодер, відкалібрований індуктивний енкодер, індуктивний кутовий енкодер, високонадійний індуктивний кутовий енкодер",
            ru: "центропласт, центропласт датчик, энкодер, абсолютный энкодер, датчик угла, датчик абсолютного угла, поворотный датчик, энкодер купить, абсолютные энкодер купить, абсолютные энкодер купить украина, датчик углового положения, индуктивный энкодер, бесконтактный энкодер, индуктивный датчик поворота, индуктивный угловой датчики, поворотные энкодер, программируемый энкодер, датчик измерения угла, индуктивный датчик угла поворота, бесконтактный датчик угла, бесконтактный датчик угла поворота, бесконтактный индуктивный датчик угла поворота, надёжный энкодер, надежный датчик поворота, надёжный угловой датчик, кольцевой энкодер, индуктивный кольцевой энкодер, откалиброванный индуктивный энкодер, индуктивный угловой энкодер, высоконадёжный индуктивный угловой энкодер"
              }
    },
    {
        id: 1,
        img: "/draft/v100/rot_v100.webp",
        img2: "/draft/v100/stat_v100.webp",
        name: "V100",
        archive: "/angleSensors.pdf",
//        archive: "/draft/v100/v100.zip",
        draft1: "/draft/v100/1.webp",
        draft2: "/draft/v100/2.webp",
        draft3: "/draft/v100/3.webp",
        draft4: "/draft/v100/4.webp",
        keywords: {
            en: "rotary angle sensor, sensor, angle measurement, cnc, rotary table, tilt angle, absolute sensor, inductive encoder, phase resonance, medical, robot, turret, guidance system, electronic compass, precise azimuth, frictionless, ring sensor",
            ua: "датчик кута повороту, сенсор, вимірювання кута, чпу, поворотний стіл, кут нахилу, абсолютний датчик, індуктивний енкодер, фазовий резонанс, медицина, робот, турель, система наведення, електронний компас, точний азимут, відсутність тертя, кільцевий датчик",
            ru: "датчик угла поворота, сенсор, измерение угла, чпу, поворотный стол, угол наклона, абсолютный датчик, индуктивный энкодер, фазовый резонанс, медицина, робот, турель, система наведения, электронный компас, точный азимут, отсутствие трения, кольцевой датчик"
          },
        description: {
            en: "rotary angle sensor, sensor, angle measurement, cnc, rotary table, tilt angle, absolute sensor, inductive encoder, phase resonance, medical, robot, turret, guidance system, electronic compass, precise azimuth, frictionless, ring sensor",
            ua: "датчик кута повороту, сенсор, вимірювання кута, чпу, поворотний стіл, кут нахилу, абсолютний датчик, індуктивний енкодер, фазовий резонанс, медицина, робот, турель, система наведення, електронний компас, точний азимут, відсутність тертя, кільцевий датчик",
            ru: "датчик угла поворота, сенсор, измерение угла, чпу, поворотный стол, угол наклона, абсолютный датчик, индуктивный энкодер, фазовый резонанс, медицина, робот, турель, система наведения, электронный компас, точный азимут, отсутствие трения, кольцевой датчик"
          }
    },
    {
        id: 2,
        img: "/draft/v150/rot_v150.webp",
        img2: "/draft/v150/stat_v150.webp",
        name: "V150",
        archive: "/angleSensors.pdf",
//        archive: "/draft/v150/v150.zip",
        draft1: "/draft/v150/1.webp",
        draft2: "/draft/v150/2.webp",
        draft3: "/draft/v150/3.webp",
        draft4: "/draft/v150/4.webp",
        draft5: "/draft/v150/5.webp",
        draft6: "/draft/v150/6.webp",
        keywords: {
            en: "rotary angle sensor, sensor, angle measurement, cnc, rotary table, tilt angle, absolute sensor, inductive encoder, phase resonance, medical, robot, turret, guidance system, electronic compass, precise azimuth, frictionless, ring sensor",
            ua: "датчик кута повороту, сенсор, вимірювання кута, чпу, поворотний стіл, кут нахилу, абсолютний датчик, індуктивний енкодер, фазовий резонанс, медицина, робот, турель, система наведення, електронний компас, точний азимут, відсутність тертя, кільцевий датчик",
            ru: "датчик угла поворота, сенсор, измерение угла, чпу, поворотный стол, угол наклона, абсолютный датчик, индуктивный энкодер, фазовый резонанс, медицина, робот, турель, система наведения, электронный компас, точный азимут, отсутствие трения, кольцевой датчик"
          },
        description: {
            en: "rotary angle sensor, sensor, angle measurement, cnc, rotary table, tilt angle, absolute sensor, inductive encoder, phase resonance, medical, robot, turret, guidance system, electronic compass, precise azimuth, frictionless, ring sensor",
            ua: "датчик кута повороту, сенсор, вимірювання кута, чпу, поворотний стіл, кут нахилу, абсолютний датчик, індуктивний енкодер, фазовий резонанс, медицина, робот, турель, система наведення, електронний компас, точний азимут, відсутність тертя, кільцевий датчик",
            ru: "датчик угла поворота, сенсор, измерение угла, чпу, поворотный стол, угол наклона, абсолютный датчик, индуктивный энкодер, фазовый резонанс, медицина, робот, турель, система наведения, электронный компас, точный азимут, отсутствие трения, кольцевой датчик"
          }
    },
    {
        id: 3,
        img: "/draft/v200/rot_v200.webp",
        name: "V200",
        archive: "/angleSensors.pdf",
//        archive: "/draft/v200/v200.zip",
        draft1: "/draft/v200/1.webp",
        draft2: "/draft/v200/2.webp",
        draft3: "/draft/v200/3.webp",
        draft4: "/draft/v200/4.webp",
        keywords: {
            en: "rotary angle sensor, sensor, angle measurement, cnc, rotary table, tilt angle, absolute sensor, inductive encoder, phase resonance, medical, robot, turret, guidance system, electronic compass, precise azimuth, frictionless, ring sensor",
            ua: "датчик кута повороту, сенсор, вимірювання кута, чпу, поворотний стіл, кут нахилу, абсолютний датчик, індуктивний енкодер, фазовий резонанс, медицина, робот, турель, система наведення, електронний компас, точний азимут, відсутність тертя, кільцевий датчик",
            ru: "датчик угла поворота, сенсор, измерение угла, чпу, поворотный стол, угол наклона, абсолютный датчик, индуктивный энкодер, фазовый резонанс, медицина, робот, турель, система наведения, электронный компас, точный азимут, отсутствие трения, кольцевой датчик"
          },
        description: {
            en: "rotary angle sensor, sensor, angle measurement, cnc, rotary table, tilt angle, absolute sensor, inductive encoder, phase resonance, medical, robot, turret, guidance system, electronic compass, precise azimuth, frictionless, ring sensor",
            ua: "датчик кута повороту, сенсор, вимірювання кута, чпу, поворотний стіл, кут нахилу, абсолютний датчик, індуктивний енкодер, фазовий резонанс, медицина, робот, турель, система наведення, електронний компас, точний азимут, відсутність тертя, кільцевий датчик",
            ru: "датчик угла поворота, сенсор, измерение угла, чпу, поворотный стол, угол наклона, абсолютный датчик, индуктивный энкодер, фазовый резонанс, медицина, робот, турель, система наведения, электронный компас, точный азимут, отсутствие трения, кольцевой датчик"
          }
    },
]

const SensorDraft: React.FC = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const { cardName = '' } = useParams<{ cardName: string }>();

    const sensor = sensorsSize.find(sensor => sensor.name.toLowerCase() === cardName.toLowerCase());

    useEffect(() => {
        console.log("Location state: ", location.state);
        if (!sensor) {
            console.error("Sensor data not found in state!");
        }
    }, [location.state, sensor]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [cardName]);

    if (!sensor) {
        return <div>Sensor not found!</div>;
    }

    const getMetaTags = (metaObj:{en: string; ua: string; ru: string})=>{
        switch(i18n.language){
            case 'ua':
                return metaObj.ua;
            case 'ru':
                return metaObj.ru;
            case 'en':
                return metaObj.en;
        }
    };



    return (
        <section className='draft'>
            <Helmet>
                <title>{sensor.name} - {t("sensor")}</title>
                <meta name="keywords" content={getMetaTags(sensor.keywords)} />
                <meta name="description" content={getMetaTags(sensor.description)} />
            </Helmet>
            <div className="draft-content wrapper">
                <div className="draft-content__main">
                    <h1>{t("draft.1")} {sensor.name}</h1>
                    <div className="draft-content__pics">
                        <img src={process.env.PUBLIC_URL + sensor.img} alt={sensor.name} />
                        {sensor.img2 && (
                            <img src={process.env.PUBLIC_URL + sensor.img2} alt={sensor.name} />
                        )}
                    </div>
                    <a href={process.env.PUBLIC_URL + sensor.archive} download>
                        <button className='btn'>
                            <span>{t("setupPdf")}</span>
                            <img src={process.env.PUBLIC_URL + "/icons/arrowRight.svg"} alt="download archive" />
                        </button>
                    </a>
                </div>
                <div className="draft-content__draft">
                    <img src={process.env.PUBLIC_URL + sensor.draft1} alt={sensor.name} />
                    <p>{t("draft.2")} {sensor.name}</p>
                </div>
                <div className="draft-content__draft">
                    <img src={process.env.PUBLIC_URL + sensor.draft2} alt={sensor.name} />
                    <p>{t("draft.3")} {sensor.name}</p>
                </div>
                <div className="draft-content__draft-small">
                    <img src={process.env.PUBLIC_URL + sensor.draft3} alt={sensor.name} />
                    <p>{t("sensor")} {sensor.name} {t("draft.4")}</p>
                </div>
                <div className="draft-content__draft-small">
                    <p>{t("sensor")} {sensor.name} {t("draft.5")}</p>
                    <img src={process.env.PUBLIC_URL + sensor.draft4} alt={sensor.name} />
                </div>
                {sensor.draft5 && (
                    <>
                        <h3 className="draft-content__title">{t("draft.6")}</h3>
                        <div className="draft-content__draft-small">
                            <img src={process.env.PUBLIC_URL + sensor.draft5} alt={sensor.name} />
                            <p>{t("draft.7")}</p>
                        </div>
                    </>
                )}
                {sensor.draft6 && (
                    <>
                        <div className="draft-content__draft">
                            <img src={process.env.PUBLIC_URL + sensor.draft6} alt={sensor.name} />
                            <p>{t("draft.8")}</p>
                        </div>
                    </>
                )}
            </div>
        </section>
    );
};

export default SensorDraft;