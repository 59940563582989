import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import './styles/index.scss';
import App from './App';
import './utils/i18n';

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <HelmetProvider>
      <App />
      </HelmetProvider>
    </React.StrictMode>
  </BrowserRouter>
);