import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

interface MetaTagsProps {
  keywords: {
    en: string;
    ua: string;
    ru: string;
  };
  description: {
    en: string;
    ua: string;
    ru: string;
  };
}

const MetaTags: React.FC<MetaTagsProps> = ({ keywords, description }) => {
  const { i18n } = useTranslation();
  
  const getTranslatedMeta = (metaObj: { en: string; ua: string; ru: string }) => {
    switch (i18n.language) {
      case 'ua':
        return metaObj.ua;
      case 'ru':
        return metaObj.ru;
      default:
        return metaObj.en;
    }
  };

  return (
    <Helmet>
      <meta name="keywords" content={getTranslatedMeta(keywords)} />
      <meta name="description" content={getTranslatedMeta(description)} />
    </Helmet>
  );
};

export default MetaTags;