import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import MetaTags from '../utils/MetaTags';
import '../styles/Faq.scss';

const Faq = () => {
    const { t } = useTranslation();

    const keywords = {
        en: "centroplast, centroplast sensor, encoder, absolute encoder,  angle sensor, absolute angle sensor, rotary sensor, buy encoder, buy absolute encoder, absolute encoder buy ukraine, angle position sensor, inductive encoder, non-contact encoder, inductive rotation sensor, inductive angle sensors, rotary encoder, programmable encoder, angle measurement sensor, inductive angle sensor, non-contact angle sensor, non-contact rotation angle sensor, non-contact inductive angle sensor, reliable encoder, reliable rotation sensor, reliable angle sensor, ring encoder, inductive ring encoder, calibrated inductive encoder, inductive rotary encoder, highly reliable inductive rotary encoder",
        ua: "центропласт, центропласт датчик, енкодер, абсолютний енкодер, датчик кута, датчик абсолютного кута, поворотний датчик, енкодер купити, абсолютні енкодер купити, абсолютні енкодер купити україна, датчик кутового положення, індуктивний енкодер, безконтактний енкодер, індуктивний датчик повороту, індуктивний кутовий датчики, поворотні енкодер, програмований енкодер, датчик вимірювання кута, iндуктивний датчик кута повороту, безконтактний датчик кута, безконтактний датчик кута повороту, безконтактний індуктивний датчик кута повороту, надійний енкодер, надійний датчик повороту, надійний кутовий датчик, кільцевий енкодер, індуктивний кільцевий енкодер, відкалібрований індуктивний енкодер, індуктивний кутовий енкодер, високонадійний індуктивний кутовий енкодер",
        ru: "центропласт, центропласт датчик, энкодер, абсолютный энкодер, датчик угла, датчик абсолютного угла, поворотный датчик, энкодер купить, абсолютные энкодер купить, абсолютные энкодер купить украина, датчик углового положения, индуктивный энкодер, бесконтактный энкодер, индуктивный датчик поворота, индуктивный угловой датчики, поворотные энкодер, программируемый энкодер, датчик измерения угла, индуктивный датчик угла поворота, бесконтактный датчик угла, бесконтактный датчик угла поворота, бесконтактный индуктивный датчик угла поворота, надёжный энкодер, надежный датчик поворота, надёжный угловой датчик, кольцевой энкодер, индуктивный кольцевой энкодер, откалиброванный индуктивный энкодер, индуктивный угловой энкодер, высоконадёжный индуктивный угловой энкодер",
      };
    
      const description = {
        en: "centroplast, centroplast sensor, encoder, absolute encoder,  angle sensor, absolute angle sensor, rotary sensor, buy encoder, buy absolute encoder, absolute encoder buy ukraine, angle position sensor, inductive encoder, non-contact encoder, inductive rotation sensor, inductive angle sensors, rotary encoder, programmable encoder, angle measurement sensor, inductive angle sensor, non-contact angle sensor, non-contact rotation angle sensor, non-contact inductive angle sensor, reliable encoder, reliable rotation sensor, reliable angle sensor, ring encoder, inductive ring encoder, calibrated inductive encoder, inductive rotary encoder, highly reliable inductive rotary encoder",
        ua: "центропласт, центропласт датчик, енкодер, абсолютний енкодер, датчик кута, датчик абсолютного кута, поворотний датчик, енкодер купити, абсолютні енкодер купити, абсолютні енкодер купити україна, датчик кутового положення, індуктивний енкодер, безконтактний енкодер, індуктивний датчик повороту, індуктивний кутовий датчики, поворотні енкодер, програмований енкодер, датчик вимірювання кута, iндуктивний датчик кута повороту, безконтактний датчик кута, безконтактний датчик кута повороту, безконтактний індуктивний датчик кута повороту, надійний енкодер, надійний датчик повороту, надійний кутовий датчик, кільцевий енкодер, індуктивний кільцевий енкодер, відкалібрований індуктивний енкодер, індуктивний кутовий енкодер, високонадійний індуктивний кутовий енкодер",
        ru: "центропласт, центропласт датчик, энкодер, абсолютный энкодер, датчик угла, датчик абсолютного угла, поворотный датчик, энкодер купить, абсолютные энкодер купить, абсолютные энкодер купить украина, датчик углового положения, индуктивный энкодер, бесконтактный энкодер, индуктивный датчик поворота, индуктивный угловой датчики, поворотные энкодер, программируемый энкодер, датчик измерения угла, индуктивный датчик угла поворота, бесконтактный датчик угла, бесконтактный датчик угла поворота, бесконтактный индуктивный датчик угла поворота, надёжный энкодер, надежный датчик поворота, надёжный угловой датчик, кольцевой энкодер, индуктивный кольцевой энкодер, откалиброванный индуктивный энкодер, индуктивный угловой энкодер, высоконадёжный индуктивный угловой энкодер",
      };

  useEffect(() => {
    window.scrollTo(0, 0);
  },);

    const faqList = [
        {
            id: 0,
            question: t("question.1"),
            answer: t("answer.1")
        },
        {
            id: 1,
            question: t("question.2"),
            answer: t("answer.2")
        },
        {
            id: 2,
            question: t("question.3"),
            answer: t("answer.3")
        },
        {
            id: 3,
            question: t("question.4"),
            answer: t("answer.4")
        },
        {
            id: 4,
            question: t("question.5"),
            answer: t("answer.5")
        },
        {
            id: 5,
            question: t("question.6"),
            answer: t("answer.6")
        },
    
    ]

    return (
        <section className='faq'>
            <MetaTags keywords={keywords} description={description} />
            <div className="faq-content wrapper">
                <h4>{t("faq")}</h4>
                <div className="faq-content__list">
                    {faqList.map((el, index) => (
                        <div className="faq-content__card" key={index}>
                            <div className="faq-content__question">
                                <h5>{el.question}</h5>
                                <img src={process.env.PUBLIC_URL + "/icons/chevronDark.svg"} alt="open" />
                            </div>
                            <div className="faq-content__answer">
                                <p>{el.answer}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Faq;